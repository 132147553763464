/* src/components/LayerSelectionOverlay.css */

.layer-selection-overlay {
    position: fixed;
    cursor: move;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0 0.2em;
    border-radius: 0.4em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: left;
  }
  
  .layer-selection-overlay-title {
    margin: 0.5em 0 0 0.5em;
    font-size: 0.8em;
    color: #333;
  }
  
  .layer-selection-overlay .IonCardContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .layer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.2em;
  }

  .layer-box {
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    border: 1px solid #bbb;
    border-radius: 4px;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .layer-box.selected {
    background-color: #333;
    color: #fff;
  }
  
  .layer-box:hover:not(.selected) {
    
    background-color: #bbb;
  }