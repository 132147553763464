.canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d2d2d2;
    overflow: hidden;
}

canvas {
    cursor: none;
    position: absolute;
    overflow: hidden;
    left: 0px;
    top: 0px; 
    right: 0px;
    bottom: 0px;
    background: #d2d2d2;
    width: 100%;
    height: 100%;
}
  
.onscreenCanvas:not(:first-of-type) {
    background: transparent;
}

.onscreenCanvas:first-of-type {
    background: #d2d2d2;
}
  