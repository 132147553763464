.closeIcon {
    position: absolute;
    z-index: 10000;
    top: 0;
    right: 0;
    padding: 0.4em;
    cursor: pointer;
    color: #333;
}

.focused {
    z-index: 9999;
}

.draggable-overlay {
    position: fixed;
    cursor: move;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0 0.2em;
    border-radius: 0.4em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: left;
}
  
.draggable-overlay-title {
  margin: 0.5em 0 0 0.5em;
  font-size: 0.8em;
  color: #333;
}

.draggable-overlay .IonCardContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookmark-button-wrapper .bookmark-button-container {
    transform: translateX(-20%);
}

.bookmark-button-container {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    z-index: 1000;
    width: 3em;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0 8px 8px 0;
}

.bookmark-button-wrapper:hover .bookmark-button-container {
    transform: translateX(0);
}

.bookmark-button {
    background: transparent;
    border: none;
    color: #333;
    font-size: 1.5em;
    padding: 10px;
    cursor: pointer;
    outline: none;
    overflow-x: visible;
}

.minimized-icon {
    color: #333;
    margin: 0 0.5em 0 0;
    padding: 0;
}

.bookmark-button-wrapper .badge-container {
    width: 40px;
    transition: transform 0.3s ease;
    transform: translateX(-20%);
    position: absolute;
    text-anchor: end;
    z-index: 9999;
    overflow-x: visible;
}

.bookmark-button-wrapper:hover .badge-container {
    transform: translateX(20%);
}