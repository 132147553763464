/* src/components/BackgroundGridToolOverlay.css */

.background-grid-tool-overlay {
    position: fixed;
    cursor: move;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0 0.2em;
    border-radius: 0.4em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: left;
  }
  
  .background-grid-tool-overlay-title {
    margin: 0.5em 0 0 0.5em;
    font-size: 0.8em;
    color: #333;
  }
  
  .background-grid-tool-overlay .IonCardContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .grid-selector {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 0.5em;
  }
  
  .grid-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    border: 1px solid #bbb;
    border-radius: 4px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    font-size: 0.9em;
  }
  
  .grid-box.selected {
    background-color: #333;
    color: #fff;
  }
  
  .grid-box:hover:not(.selected) {
    background-color: #bbb;
  }