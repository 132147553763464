/* src/components/QrCodeOverlay.css */
.qr-code-overlay {
    position: fixed;
    cursor: move;
    top: 1em;
    left: 1em;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0 0.2em;
    border-radius: 0.4em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: left;
}

.qr-code-overlay-img {
    width: 100%;
    height: 100%;
}

.qr-code-overlay div {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
}
.qr-code-overlay-title {
    margin: 0.5em 0 0 0.5em;
    font-size: 0.8em;
    color: #333;
}