.user-list {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.user-avatar {
    width: 0.8em;
    height: 0.8em;
    margin: 1.5em 0.5em 0 0;
    padding: 0.5em;
    transition: background-color 0.3s ease;
}

.user-avatar svg {
    width: 0.8em;
    height: 0.8em;
}

.user-name {
    margin: 0.5em 1em 0.5em 0;
    font-size: 0.8em;
    line-height: 1em;
    color: #333;
}

.user-count {
    margin: 1.5em 1em 0.5em 0;
    font-size: 0.8em;
    line-height: 1em;
    color: #333;
}