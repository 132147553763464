/* src/components/ColorPaletteOverlay.css */
.color-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.2em;
}

.color-box {
  width: 24px;
  height: 24px;
  margin: 3px;
  border-radius: 4px;
  cursor: pointer;
}

.color-box.selected {
  outline: 4px solid #545454;
}
  