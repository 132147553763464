/* src/components/ToolSelectorOverlay.css */

.tool-selector-overlay {
    position: fixed;
    cursor: move;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0 0.2em;
    border-radius: 0.4em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: left;
  }
  
  .tool-selector-overlay-title {
    margin: 0.5em 0 0 0.5em;
    font-size: 0.8em;
    color: #333;
  }
  
  .tool-selector-overlay .IonCardContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tool-grid {
    display: flex;
    gap: 0.5em;
  }
  
  .tool-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    border: 1px solid #bbb;
    border-radius: 4px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
  
  .tool-box.selected {
    background-color: #333;
    color: #fff;
  }
  
  .tool-box:hover:not(.selected) {
    background-color: #bbb;
  }
  