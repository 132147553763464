.post-it-note-container {
    position: absolute;
    width: 200px;
    height: 200px;
    z-index: 100;
}

.post-it-editor {
    --max-width: 100%;
    --max-height: 100%;
}

.post-it-editor-canvas {
    background: transparent;
    background-color: #ffff00fa;
}

.post-it-editor-brush-catcher {
    width: 100%;
    height: 100%;
    cursor: none;
    user-select: none;
}

.pen-cursor {
    color: #0a0a0a;
    cursor: none;
    user-select: none;
    pointer-events: none;
}

.post-it-note {
    background-color: #ffff00fa;
    border: 1px solid black;
    
    margin: 0px;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.post-it-subtitle {
    color: #ee0 !important;
}

/* Backdrop */
.post-it-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

/* Modal */
.post-it-modal {
    position: relative;
    width: 400px;
    height: 400px;
    max-height: 400px;
    max-width: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    animation: fadeIn 0.3s ease-out;
}

/* Close Button */
.post-it-modal-close {
    position: absolute;
    top: 2px;
    right: 8px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    z-index: 10001;
}
  
/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.post-it-canvas {
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer; 
    cursor: hand;
}

.post-it-toolstrip-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 1.5em;
}

.post-it-toolstrip {
    position: absolute;
    display: flex;
    width: 100px;
    justify-content: space-between;
    padding: 0.5em;
    z-index: 10001;
}